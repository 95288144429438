import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";
import ContactComponent from "src/components/contact/Component";

export const Contacto = (props) => {
  return (
    <LayoutGeneral {...{ ...props, pageName: "Contacto" }}>
      <Content className="page contacto">
        <ContactComponent />
      </Content>
    </LayoutGeneral>
  );
};

export default Contacto;
