import React from "react";
import { Col, Tag } from "antd";
import { ContactForm } from "src/components/contact/Form";

import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

import "src/components/contact/style.scss";

export const ContactComponent = (props) => {
  return (
    <>
      <h1 className="pageTitle">Contacte con nosotros:</h1>
      <div className="socialNetworksInContact">
        <Tag icon={<FacebookOutlined />} color="#3b5999">
          Facebook
        </Tag>

        <Tag icon={<InstagramOutlined />} color="#000000	">
          Instagram
        </Tag>
      </div>
      <div className="clearer"></div>
      <p>
        Si tiene cualquier duda acerca de los productos, desea realizar un
        pedido o solicitar un presupuesto, por favor, no dude en ponerse en
        contacto con nosotros. Puede rellenar el siguiente formulario o
        enviarnos un email a{" "}
        <a mailTo="revestecnia@revestecnia.com">revestecnia@revestecnia.com</a>
      </p>

      <Col
        {...{
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 20 },
          lg: { span: 20 },
        }}
      >
        <ContactForm />
      </Col>
    </>
  );
};

export default ContactComponent;
