export const comunidadesProvincias = [
  {
    label: "Andalucía",
    value: "Andalucía",
    children: [
      {
        label: "Almería",
        value: "Almería",
      },
      {
        label: "Cádiz",
        value: "Cádiz",
      },
      {
        label: "Córdoba",
        value: "Córdoba",
      },
      { label: "Granada", value: "Granada" },
      { label: "Huelva", value: "Huelva" },
      { label: "Jaén", value: "Jaén" },
      { label: "Málaga", value: "Málaga" },
      { label: "Sevilla", value: "Sevilla" },
    ],
  },
  {
    label: "Aragón",
    value: "Aragón",

    children: [
      { label: "Huesca", value: "Huesca" },
      { label: "Teruel", value: "Teruel" },
      { label: "Zaragoza", value: "Zaragoza" },
    ],
  },

  {
    label: "Asturias",
    value: "Asturias",
    children: [{ label: "Oviedo", value: "Oviedo" }],
  },
  {
    label: "Baleares",
    value: "Baleares",
  },
  {
    label: "Canarias",
    value: "Canarias",
    children: [
      { label: "Santa Cruz de Tenerife", value: "Santa Cruz de Tenerife" },
      {
        label: "Las Palmas de Gran Canaria",
        value: "Las Palmas de Gran Canaria",
      },
    ],
  },
  {
    label: "Cantabria",
    value: "Cantabria",
  },
  {
    label: "Castilla-La Mancha",
    value: "Castilla-La Mancha",
    children: [
      { label: "Albacete", value: "Albacete" },
      { label: "Ciudad Real", value: "Ciudad Real" },
      { label: "Cuenca", value: "Cuenca" },
      { label: "Guadalajara", value: "Guadalajara" },
      { label: "Toledo", value: "Toledo" },
    ],
  },
  {
    label: "Castilla y León",
    value: "Castilla y León",
    children: [
      { label: "Ávila", value: "Ávila" },
      { label: "Burgos", value: "Burgos" },
      { label: "León", value: "León" },
      { label: "Salamanca", value: "Salamanca" },
      { label: "Segovia", value: "Segovia" },
      { label: "Soria", value: "Soria" },
      { label: "Valladolid", value: "Valladolid" },
      { label: "Zamora", value: "Zamora" },
    ],
  },

  {
    label: "Cataluña",
    value: "Cataluña",
    children: [
      { label: "Barcelona", value: "Barcelona" },
      { label: "Gerona", value: "Gerona" },
      { label: "Lérida", value: "Lérida" },
      { label: "Tarragona", value: "Tarragona" },
    ],
  },

  {
    label: "Comunidad Valenciana ",
    value: "Comunidad Valenciana ",
    children: [
      { label: "Alicante", value: "Alicante" },
      { label: "Castellón de la Plana", value: "Castellón de la Plana" },
      { label: "Valencia", value: "Valencia" },
    ],
  },

  {
    label: "Extremadura",
    value: "Extremadura",
    children: [
      { label: "Badajoz", value: "Badajoz" },
      { label: "Cáceres", value: "Cáceres" },
    ],
  },
  {
    label: "Galicia",
    value: "Galicia",
    children: [
      { label: "La Coruña", value: "La Coruña" },
      { label: "Lugo", value: "Lugo" },
      { label: "Orense", value: "Orense" },
      { label: "Pontevedra", value: "Pontevedra" },
    ],
  },
  {
    label: "Madrid",
    value: "Madrid",
  },
  {
    label: "Murcia",
    value: "Murcia",
  },

  {
    label: "Navarra",
    value: "Navarra",
  },
  {
    label: "País Vasco",
    value: "País Vasco",
    children: [
      { label: "Álava", value: "Álava" },
      { label: "Guipúzcoa", value: "Guipúzcoa" },
      { label: "Vizcaya", value: "Vizcaya" },
    ],
  },
  {
    label: "La Rioja",
    value: "La Rioja",
  },
];

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },

    md: {
      span: 16,
      offset: 8,
    },
  },
};

export const ppInfoLayout = {
  xs: { span: 24, offset: 0 },
  sm: {
    span: 16,
    offset: 8,
  },
};
