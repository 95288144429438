import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  Input,
  Cascader,
  Select,
  Result,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from "antd";

import {
  comunidadesProvincias,
  formItemLayout,
  tailFormItemLayout,
  ppInfoLayout,
} from "src/components/contact/consts";
import { Link } from "gatsby";

const { Option } = Select;

export const ContactForm = () => {
  //  const [form] = Form.useForm();
  const { reset, getValues } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const [errorsInForm, setErrorsInForm] = useState([]);
  const {
    formState: { isSubmitting },
  } = useForm();

  const onFinish = async (data) => {
    console.log("Received values of form: ", data);

    // send email from inbox 1 to inbox 2
    // NOTE you can send emails to any address with MailSlurp

    if (!data.agreement) {
      setErrorsInForm((errors) => [
        ...errors,
        ["Tiene que aceptar la política de privacidad"],
      ]);
      return;
    }

    const GATEWAY_URL =
      "https://lf8zgq3w55.execute-api.eu-west-1.amazonaws.com/prod";

    try {
      await fetch(GATEWAY_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      reset();
      setSubmitted(true);
    } catch (error) {
      // handle server errors
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="34">+ 34</Option>
      </Select>
    </Form.Item>
  );

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [
          {
            label: "Exteriores/Fachadas-ThermoProtect",
            value: "thermoProtect",
          },
          { label: "Interiores-ThermoPlus", value: "thermoPlus" },
          { label: "Cubiertas/Tejados-ThermoActive", value: "thermoActive" },
          {
            label: "Usos industriales-IndustrySpecial",
            value: "industrySpecial",
          },
        ].filter((option) => option.label.includes(value))
      );
    }
  };
  const showThankYou = (
    <Result
      status="success"
      title="Su contacto se ha realizado con éxito"
      subTitle="En breve nos pondremos en contacto con usted"
      extra={[
        <Button
          type="primary"
          key="console"
          onClick={() => setSubmitted(false)}
        >
          Ok
        </Button>,
      ]}
    />
  );

  return submitted ? (
    showThankYou
  ) : (
    <>
      {errorsInForm.length
        ? errorsInForm.map((e, key) => (
            <span key={key + "d"} className="error">
              e
            </span>
          ))
        : ""}
      <Form
        {...{
          ...formItemLayout,
          disabled: isSubmitting,
          name: "contact",
          onFinish,
          initialValues: {
            prefix: "34",
          },
          scrollToFirstError: true,
        }}
      >
        <Form.Item
          name="nombre"
          label="Nombre"
          rules={[
            {
              required: true,
              message:
                "Por favor díganos un nombre con el que poder dirigirnos a usted",
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="apellidos"
          label="Apellidos"
          rules={[
            {
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "La dirección de email no es válida",
            },
            {
              required: true,
              message: "Por favor apórtenos una dirección de email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="comunidadProvincia"
          label="Comunidad/Provincia"
          rules={[
            {
              type: "array",
              required: true,
              message:
                "Por favor díganos Comunidad Autónoma/Provinca para contactar a la persona adecuada más rápido",
            },
          ]}
        >
          <Cascader options={comunidadesProvincias} />
        </Form.Item>

        <Form.Item name="phone" label="Teléfono de contacto">
          <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name="interes" label="Producto de interés">
          <AutoComplete
            options={autoCompleteResult}
            onChange={onWebsiteChange}
            placeholder="Producto o aplicación en la que está más interesado"
          >
            <Input />
          </AutoComplete>
        </Form.Item>

        <Form.Item name="contactarPreferentemente" label="Modo de contacto">
          <Select placeholder="especifique si quiere que le llamemos o enviemos email">
            <Option value="email">Email</Option>
            <Option value="phone">Telf/Móvil</Option>
          </Select>
        </Form.Item>

        <Col {...ppInfoLayout}>
          <ul className="ppInfo">
            <li>
              <strong>Responsable de los datos:</strong> Revestecnia Soluciones
              Avanzadas, S.L.
            </li>
            <li>
              <strong>Finalidad:</strong> Contacto posterior con el fin de
              registrarlo en nuestra base de clientes o satisfacer la necesidad
              de información.
            </li>
            <li>
              <strong>Legitimización:</strong> La aceptación de la Política de
              Privacidad Destino: Un email y una base de datos de clientes
              gestionada por Revestecnia Soluciones Avanzadas, S.L. Derechos:
              Tienes derecho al derecho al acceso, rectificación, supresión,
              limitación, portabilidad y olvido de sus datos
            </li>
          </ul>
        </Col>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Tiene que aceptar la política de privacidad")
                    ),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox>
            He leído y acepto la{" "}
            <Link to="/politica-de-privacidad/">política de privacidad</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button htmlType="submit" disabled={isSubmitting}>
            Enviar contacto
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ContactForm;
